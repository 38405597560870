<script setup lang="ts">
const route = useRoute();
const backgroundName = (route.meta?.backgroundName || "") as string;
const title = computed<string>(() => {
    const title = (route.meta?.title || route.params?.name || route.params?.mainname || "") as string;
    return title.replaceAll(/[_,;:'\s"?!@#$%^&*\-=+"']+/gi, " ");
});
</script>

<template>
    <NuxtLayout>
        <template #header>
            <SubPageHeader :title="title" :background-name="backgroundName" />
        </template>
        <template #default> <slot></slot> </template>
    </NuxtLayout>
</template>
